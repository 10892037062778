import type { Route } from 'nextjs-routes';

import config from 'configs/app';

const TEMPLATE_MAP: Record<Route['pathname'], string> = {
  '/': '%network_name% 区块链浏览器 - 查看 %network_name% 状态',
  '/txs': '%network_name% 交易 - %network_name% 浏览器',
  '/txs/kettle/[hash]': '%network_name% kettle %hash% 交易',
  '/tx/[hash]': '%network_name% 交易 %hash%',
  '/blocks': '%network_name% 区块',
  '/block/[height_or_hash]': '%network_name% 区块 %height_or_hash%',
  '/block/countdown': '%network_name% 区块倒计时索引',
  '/block/countdown/[height]': '%network_name% 区块 %height% 倒计时',
  '/accounts': '%network_name% 顶级账户',
  '/accounts/label/[slug]': '%network_name% 按标签搜索的地址',
  '/address/[hash]': '%network_name% 地址详情 %hash%',
  '/verified-contracts': '已验证 %network_name% 合约查询 - %network_name% 浏览器',
  '/contract-verification': '%network_name% 验证合约',
  '/address/[hash]/contract-verification': '%network_name% 合约验证 %hash%',
  '/tokens': '代币列表 - %network_name% 浏览器',
  '/token/[hash]': '%network_name% 代币详情',
  '/token/[hash]/instance/[id]': '%network_name% NFT 实例',
  '/apps': '%network_name% DApps - 探索顶级应用',
  '/apps/[id]': '%network_name% 市场应用',
  '/stats': '%network_name% 统计数据 - %network_name% 网络分析',
  '/stats/[id]': '%network_name% 统计数据 - %id% 图表',
  '/api-docs': '%network_name% API 文档 - %network_name% 开发者工具',
  '/graphiql': '%network_name% GraphQL - %network_name% 数据查询',
  '/search-results': '%network_name% 搜索结果 %q%',
  '/auth/profile': '%network_name% - 我的个人资料',
  '/account/watchlist': '%network_name% - 关注列表',
  '/account/api-key': '%network_name% - API 密钥',
  '/account/custom-abi': '%network_name% - 自定义 ABI',
  '/account/tag-address': '%network_name% - 私有标签',
  '/account/verified-addresses': '%network_name% - 我验证的地址',
  '/public-tags/submit': '%network_name% - 公共标签请求',
  '/withdrawals': '%network_name% 提现 - 在 %network_name% 浏览器上追踪',
  '/visualize/sol2uml': '%network_name% Solidity UML 图',
  '/csv-export': '%network_name% 导出数据到 CSV',
  '/deposits': '%network_name% 存款（L1 > L2）',
  '/output-roots': '%network_name% 输出根',
  '/dispute-games': '%network_name% 争议游戏',
  '/batches': '%network_name% 交易批次',
  '/batches/[number]': '%network_name% L2 交易批次 %number%',
  '/blobs/[hash]': '%network_name% blob %hash% 详情',
  '/ops': '%network_name% 用户操作 - %network_name% 浏览器',
  '/op/[hash]': '%network_name% 用户操作 %hash%',
  '/404': '%network_name% 错误 - 页面未找到',
  '/name-domains': '%network_name% 名称域 - %network_name% 浏览器',
  '/name-domains/[name]': '%network_name% %name% 域名详情',
  '/validators': '%network_name% 验证者列表',
  '/gas-tracker': '%network_name% 燃气追踪器 - 当前燃气费',
  '/mud-worlds': '%network_name% MUD 世界列表',
  '/token-transfers': '%network_name% 代币转账',

  // service routes, added only to make typescript happy
  '/login': '%network_name% 登录',
  '/sprite': '%network_name% SVG 图标',
  '/api/metrics': '%network_name% 节点 API prometheus 指标',
  '/api/monitoring/invalid-api-schema': '%network_name% 节点 API prometheus 指标',
  '/api/log': '%network_name% 节点 API 请求日志',
  '/api/media-type': '%network_name% 节点 API 媒体类型',
  '/api/proxy': '%network_name% 节点 API 代理',
  '/api/csrf': '%network_name% 节点 API CSRF 令牌',
  '/api/healthz': '%network_name% 节点 API 健康检查',
  '/api/config': '%network_name% 节点 API 应用配置',
  '/api/sprite': '%network_name% 节点 API SVG 图标内容',
  '/auth/auth0': '%network_name% 认证',
  '/auth/unverified-email': '%network_name% 未验证的电子邮件',
};

const TEMPLATE_MAP_ENHANCED: Partial<Record<Route['pathname'], string>> = {
  '/token/[hash]': '%network_name% %symbol% 代币详情',
  '/token/[hash]/instance/[id]': '%network_name% %symbol% 代币实例',
  '/apps/[id]': '%network_name% - %app_name%',
  '/address/[hash]': '%network_name% 地址详情 %domain_name%',
  '/stats/[id]': '%network_name% 的 %title% 图表',
};

export function make(pathname: Route['pathname'], isEnriched = false) {
  const template = (isEnriched ? TEMPLATE_MAP_ENHANCED[pathname] : undefined) ?? TEMPLATE_MAP[pathname];
  const postfix = config.meta.promoteBlockscoutInTitle ? ' | Blockscout' : '';

  return (template + postfix).trim();
}
