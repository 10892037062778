/* eslint-disable max-len */
import type { Route } from 'nextjs-routes';

// equal og:description
// eslint-disable-next-line max-len
const DEFAULT_TEMPLATE = '%network_title% 复旦大学区块链课程适配，全仿真区块链实训场景，基于领先的开源区块链浏览器Blockscout。基于 Blockscout 的数据可用性、API 和生态系统工具来支持测试教学区块链网络。';

// FIXME all page descriptions will be updated later
const TEMPLATE_MAP: Record<Route['pathname'], string> = {
  '/': DEFAULT_TEMPLATE,
  '/txs': DEFAULT_TEMPLATE,
  '/txs/kettle/[hash]': DEFAULT_TEMPLATE,
  '/tx/[hash]': '查看 %network_title% 上的交易 %hash%',
  '/blocks': DEFAULT_TEMPLATE,
  '/block/[height_or_hash]': '查看区块 %height_or_hash% 的交易、代币转账和叔块',
  '/block/countdown': DEFAULT_TEMPLATE,
  '/block/countdown/[height]': DEFAULT_TEMPLATE,
  '/accounts': DEFAULT_TEMPLATE,
  '/accounts/label/[slug]': DEFAULT_TEMPLATE,
  '/address/[hash]': '查看 %network_title% 上 %hash% 的账户余额、交易和其他数据',
  '/verified-contracts': DEFAULT_TEMPLATE,
  '/contract-verification': DEFAULT_TEMPLATE,
  '/address/[hash]/contract-verification': '查看 %network_title% 上 %hash% 的账户余额、交易和其他数据',
  '/tokens': DEFAULT_TEMPLATE,
  '/token/[hash]': '%hash%，在 %network_title% 上的余额和分析',
  '/token/[hash]/instance/[id]': '%hash%，在 %network_title% 上的余额和分析',
  '/apps': DEFAULT_TEMPLATE,
  '/apps/[id]': DEFAULT_TEMPLATE,
  '/stats': DEFAULT_TEMPLATE,
  '/stats/[id]': DEFAULT_TEMPLATE,
  '/api-docs': DEFAULT_TEMPLATE,
  '/graphiql': DEFAULT_TEMPLATE,
  '/search-results': DEFAULT_TEMPLATE,
  '/auth/profile': DEFAULT_TEMPLATE,
  '/account/watchlist': DEFAULT_TEMPLATE,
  '/account/api-key': DEFAULT_TEMPLATE,
  '/account/custom-abi': DEFAULT_TEMPLATE,
  '/account/tag-address': DEFAULT_TEMPLATE,
  '/account/verified-addresses': DEFAULT_TEMPLATE,
  '/public-tags/submit': '为您的地址、合约或 dApp 的一组合约提出新的公共标签。我们的团队将审核并批准您的提交。公共标签是帮助用户识别合约和地址的绝佳工具。',
  '/withdrawals': DEFAULT_TEMPLATE,
  '/visualize/sol2uml': DEFAULT_TEMPLATE,
  '/csv-export': DEFAULT_TEMPLATE,
  '/deposits': DEFAULT_TEMPLATE,
  '/output-roots': DEFAULT_TEMPLATE,
  '/dispute-games': DEFAULT_TEMPLATE,
  '/batches': DEFAULT_TEMPLATE,
  '/batches/[number]': DEFAULT_TEMPLATE,
  '/blobs/[hash]': DEFAULT_TEMPLATE,
  '/ops': DEFAULT_TEMPLATE,
  '/op/[hash]': DEFAULT_TEMPLATE,
  '/404': DEFAULT_TEMPLATE,
  '/name-domains': DEFAULT_TEMPLATE,
  '/name-domains/[name]': DEFAULT_TEMPLATE,
  '/validators': DEFAULT_TEMPLATE,
  '/gas-tracker': DEFAULT_TEMPLATE,
  '/mud-worlds': DEFAULT_TEMPLATE,
  '/token-transfers': DEFAULT_TEMPLATE,

  // service routes, added only to make typescript happy
  '/login': DEFAULT_TEMPLATE,
  '/sprite': DEFAULT_TEMPLATE,
  '/api/metrics': DEFAULT_TEMPLATE,
  '/api/monitoring/invalid-api-schema': DEFAULT_TEMPLATE,
  '/api/log': DEFAULT_TEMPLATE,
  '/api/media-type': DEFAULT_TEMPLATE,
  '/api/proxy': DEFAULT_TEMPLATE,
  '/api/csrf': DEFAULT_TEMPLATE,
  '/api/healthz': DEFAULT_TEMPLATE,
  '/api/config': DEFAULT_TEMPLATE,
  '/api/sprite': DEFAULT_TEMPLATE,
  '/auth/auth0': DEFAULT_TEMPLATE,
  '/auth/unverified-email': DEFAULT_TEMPLATE,
};

const TEMPLATE_MAP_ENHANCED: Partial<Record<Route['pathname'], string>> = {
  '/stats/[id]': '%description%',
};

export function make(pathname: Route['pathname'], isEnriched = false) {
  return (isEnriched ? TEMPLATE_MAP_ENHANCED[pathname] : undefined) ?? TEMPLATE_MAP[pathname] ?? '';
}
